import React, { useEffect } from 'react'

import { useParams } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { AppPlugin } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { Loader } from '@atoms/notifications'
import { Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CartFragment, useCustomerQuery, CheckoutFragment, useCustomerOrderQuery, MoneyFragment, useGetStoreConfigQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { PaymentMethodEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('margin-top', { tablet: '56px', desktop: '56px' })}
  .card {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .order-title {
    ${ResponsivePXValue('margin-bottom', { mobile: '8px', tablet: '16px', desktop: '16px' })}
  }
`

const InfoBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }): string => theme.colors.green.swansDown};
  ${ResponsivePXValue('gap', { mobile: '16px', tablet: '16px', desktop: '16px' })}
  ${ResponsivePXValue('padding', { mobile: '24px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('margin', { mobile: '0', tablet: '24px 24px 0', desktop: '24px 24px 0' })}

  .email {
    color: ${({ theme }): string => theme.colors.green.watercourse};
  }
`

const WarningBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.orange.casablanca, 0.3)};
  ${ResponsivePXValue('gap', { mobile: '16px', tablet: '16px', desktop: '16px' })}
  ${ResponsivePXValue('padding', { mobile: '24px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('margin', { mobile: '0', tablet: '24px 24px 0', desktop: '24px 24px 0' })}
`

const BankingBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.orange.casablanca, 0.3)};
  ${ResponsivePXValue('gap', { mobile: '8px', tablet: '8px', desktop: '8px' })}
  ${ResponsivePXValue('padding', { mobile: '24px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('margin', { mobile: '0', tablet: '24px 24px 0', desktop: '24px 24px 0' })}
`

const InfoTable = styled.div`
  ${ResponsivePXValue('padding', { mobile: '24px 0', tablet: '24px 0', desktop: '24px 0' })}
  ${ResponsivePXValue('margin', { mobile: '14px 16px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('width', { mobile: 'CALC(100% - 32px)', tablet: 'CALC(100% - 48px)', desktop: 'CALC(100% - 48px)' })}
  .info-title {
    ${ResponsivePXValue('margin-bottom', '10px')}
  }
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const BankTable = styled.div`
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '50%', desktop: '50%' })}
  ${ResponsivePXValue('padding', { mobile: '12px 0', tablet: '12px 0', desktop: '12px 0' })}
`

const BankRow = styled.div`
  display: flex;

  > * {
    flex-basis: 50%;
    display: flex;
    align-items: center;
  }
`

const Strong = styled.span`
  font-weight: 600;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', { mobile: '12px', tablet: '12px', desktop: '12px' })}
  .order-button {
    ${ResponsivePXValue('width', { mobile: 'fit-content', tablet: '190px', desktop: '190px' })}
  }
  ${ResponsivePXValue('width', { mobile: 'CALC(100% - 32px)', tablet: 'CALC(100% - 48px)', desktop: 'CALC(100% - 48px)' })}
  ${ResponsivePXValue('margin', { mobile: '16px', tablet: '24px', desktop: '24px' })}
`

const Card = styled.div`
  width: 100%;
  ${ResponsivePXValue('padding', { mobile: '16px 0', tablet: '24px', desktop: '24px' })}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

export interface DetailsProps {
  checkout: CheckoutFragment
  cart: CartFragment
}

export function Details({ cart }: DetailsProps): JSX.Element {

  let { id: number } = useParams<{ step: string, id: string }>()
  const isRedirect = ['success', 'failure'].includes(number)
  if (isRedirect && localStorage?.getItem('orderId')) {
    number = localStorage?.getItem('orderId')
  }
  const { data: customerData } = useCustomerQuery()
  const { data: orderData, loading: orderLoading } = useCustomerOrderQuery({ variables: { number } })
  const { data: configData } = useGetStoreConfigQuery()
  const theme = useTheme()

  const order = orderData?.customer?.orders?.items?.[0]
  const paidAmount = { value: order?.invoices?.reduce((prev, current) => prev + current.total.grandTotal.value, 0) || 0 }
  const outstandingAmount = order?.total?.totalDue?.value || 0
  const paymentMethod = order?.paymentMethods?.[0]
  const points = cart?.amastyRewardsHighlights?.captionText || '0'

  useEffect(() => {
    AppPlugin.shared().hideFooter()
    return () => {
      AppPlugin.shared().showFooter()
    }
  }, [])

  return (
    <Container>
      <Card className='card'>
        <Choose>
          <When condition={orderLoading}>
            <Title variant='t3' className='order-title'>
              {`Order #${number}`}
            </Title>
            <Loader />
          </When>
          <When condition={!!order}>
            <Title variant='t3' className='order-title'>
              {`Order #${number}`}
            </Title>
            <Choose>
              <When condition={!!outstandingAmount}>
                <Choose>
                  <When condition={paymentMethod.type === PaymentMethodEnum.peachpayments_s2s}>
                    <WarningBlock>
                      <Paragraph align='center'>
                        Payment of <Strong>R{outstandingAmount}</Strong> needed to confirm your order.<br />
                        Please contact customer service on <Strong>{configData?.storeConfig?.transEmailIdentSupportEmail}</Strong> or <Strong>{configData?.storeConfig?.generalStoreInformationPhone}</Strong>
                      </Paragraph>
                    </WarningBlock>
                  </When>
                  <Otherwise>
                    <BankingBlock>
                      <Paragraph align='center'>
                        Make an EFT payment for amount of <Strong>R{outstandingAmount}</Strong> into Faithful to Nature&apos;s bank account, details below:
                      </Paragraph>
                      <BankTable>
                        <BankRow>
                          <Paragraph>
                            Reference:
                          </Paragraph>
                          <Paragraph bold>
                            #{number}
                          </Paragraph>
                        </BankRow>
                        <BankRow>
                          <Paragraph>
                            Amount:
                          </Paragraph>
                          <Paragraph bold>
                            R{outstandingAmount}
                          </Paragraph>
                        </BankRow>
                        <BankRow>
                          <Paragraph>
                            Bank Name:
                          </Paragraph>
                          <Paragraph bold>
                            Nedbank
                          </Paragraph>
                        </BankRow>
                        <BankRow>
                          <Paragraph>
                            Account Name:
                          </Paragraph>
                          <Paragraph bold>
                            Faithful To Nature
                          </Paragraph>
                        </BankRow>
                        <BankRow>
                          <Paragraph>
                            Account Number:
                          </Paragraph>
                          <Paragraph bold>
                            1010135546
                          </Paragraph>
                        </BankRow>
                        <BankRow>
                          <Paragraph>
                            Account Type:
                          </Paragraph>
                          <Paragraph bold>
                            Current Account
                          </Paragraph>
                        </BankRow>
                        <BankRow>
                          <Paragraph>
                            Branch Code:
                          </Paragraph>
                          <Paragraph bold>
                            198765 (for Standard bank users 19876500)
                          </Paragraph>
                        </BankRow>
                        <BankRow>
                          <Paragraph>
                            Branch Name:
                          </Paragraph>
                          <Paragraph bold>
                            Gardens
                          </Paragraph>
                        </BankRow>
                      </BankTable>
                      <Paragraph>
                        Please email POP to <Strong>{configData?.storeConfig?.transEmailIdentSupportEmail}</Strong> to complete payment
                      </Paragraph>
                    </BankingBlock>
                  </Otherwise>
                </Choose>
              </When>
              <Otherwise>
                <InfoBlock>
                  <Paragraph align='center'>
                    An email has been sent to <span className='email'>{customerData?.currentCustomer?.email || 'your email address' }</span><br />
                    Please check your spam folder if you don&apos;t get an email from us.
                  </Paragraph>
                  <Paragraph align='center'>
                    Thank you for shopping with us, you&apos;re making a difference every time you do.
                  </Paragraph>
                </InfoBlock>
              </Otherwise>
            </Choose>
            <InfoTable>
              <DeviceContainer mobile>
                <Title variant='t4' className='info-title'>
                  Order Details
                </Title>
              </DeviceContainer>
              <InfoRow>
                <Paragraph>
                  Payment method
                </Paragraph>
                <Paragraph>
                  {order.paymentMethods?.[0].name}
                </Paragraph>
              </InfoRow>
              <InfoRow>
                <Paragraph>
                  Amount paid
                </Paragraph>
                <Paragraph color={theme.colors.green.watercourse} bold>
                  R{SiteHelper.formatCurrency(paidAmount as unknown as MoneyFragment)}
                </Paragraph>
              </InfoRow>
              <InfoRow>
                <Paragraph>
                  Delivery method
                </Paragraph>
                <Paragraph>
                  {order.carrier}
                </Paragraph>
              </InfoRow>
              <InfoRow>
                <Paragraph>
                  Delivery address
                </Paragraph>
                <Paragraph align='right'>
                  {order.shippingAddress.street}<br />{order.shippingAddress.city}<br />{order.shippingAddress.postcode}
                </Paragraph>
              </InfoRow>
            </InfoTable>
            <Buttons>
              <Button title='VIEW ORDER' variant='ghost' className='order-button' size='medium' href='/me/orders' />
              <Button title='CONTINUE SHOPPING' variant='primary' className='order-button' size='medium' href='/' onClick={() => null} />
            </Buttons>
          </When>
          <Otherwise>
            <Title variant='t3'>
              Error
            </Title>
            <Paragraph>
              Failed to get order information
            </Paragraph>
          </Otherwise>
        </Choose>
      </Card>
    </Container>
  )

}
