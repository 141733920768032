import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { Loader } from '@atoms/notifications'
import { Heading, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageMyDiscountsSectionFragment, PersonalDiscountFragment, ProductListFragment, useCustomerQuery, useGetAppQuery, usePersonalDiscountsLazyQuery, usePersonalDiscountsQuery } from '@hooks/api'
import { PageBanner } from '@molecules/content/PageBanner'
import { ProductSlider } from '@organisms/shop/ProductSlider'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', '32px')}
`

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${ResponsivePXValue('gap', { mobile: '8px', tablet: '12px', desktop: '12px' })}
  ${ResponsivePXValue('padding', { mobile: '10px', tablet: '', desktop: '' })}
  background-color: ${(props): string => props.backgroundColor};


  .block-title {
    ${ResponsivePXValue('margin', { mobile: '0 10px', tablet: '0 10px', desktop: '0' })}
  }

  .block-paragraph {
    ${ResponsivePXValue('margin', { mobile: '0 10px', tablet: '0 10px', desktop: '0' })}
  }

`

const HeadingContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('justify-content', { mobile: 'center', tablet: 'space-between', desktop: 'space-between' })}
`

const CustomerPromoContainer = styled.div`
  ${(props): string => ResponsivePXValue('background-color', { mobile: props.theme.colors.white.pureWhite })}
  ${ResponsivePXValue('padding', { mobile: '8px 16px' })}
  ${ResponsivePXValue('display', { mobile: 'block', tablet: 'flex', desktop: 'flex' })}
  ${ResponsivePXValue('flex-direction', { tablet: 'column', desktop: 'column' })}
  ${ResponsivePXValue('gap', { tablet: '12px', desktop: '12px' })}

`

const PromoDetails = styled.div`
  display: flex;
  ${ResponsivePXValue('align-items', { mobile: 'center', tablet: 'flex-end', desktop: 'flex-end' })}
  flex-direction: row;

  .seeAllBtn {
    width: 100%;
    white-space: nowrap;
  }
`

const ButtonContainer = styled.div`
  display: flex;
`

const Details = styled.div`
  width: 100%;
  display: flex;
  ${ResponsivePXValue('align-items', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('flex-direction', { mobile: 'column' })}
  ${ResponsivePXValue('gap', { mobile: '12px' })}
  ${ResponsivePXValue('margin-top', { mobile: '8px' })}
`

const PromoCodeChip = styled.span`
  border: 2px solid ${(props): string => props.theme.colors.green.viridian}; 
  background-color: ${(props): string => props.theme.colors.green.swansDown};
  color: ${(props): string => props.theme.colors.green.viridian};
  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}
  ${ResponsivePXValue('line-height', { mobile: '18px', tablet: '20px', desktop: '22px' })}  
  border-radius: 16px; 
  ${ResponsivePXValue('padding', '5px')}
  ${ResponsivePXValue('margin-left', { desktop: '24px' })}
  cursor: pointer;
`

export interface PageMyDiscountsSectionProps {
  section: PageMyDiscountsSectionFragment
  showPlaceholder?: boolean
}

enum DisplayTypeEnum {
  LOADING = 'LOADING',
  EMPTY = 'EMPTY',
  DISCOUNTS = 'DISCOUNTS',
}

interface PageMyDiscountsSectionState {
  displayType: DisplayTypeEnum
}

const DEFAULT_STATE: PageMyDiscountsSectionState = {
  displayType: DisplayTypeEnum.LOADING,
}

export function PageMyDiscountsSection({ section, showPlaceholder = false }: PageMyDiscountsSectionProps): JSX.Element {

  const theme = useTheme()
  const [state, setState] = useState<PageMyDiscountsSectionState>({ ...DEFAULT_STATE })
  const { addToast } = useSimpleToasts()
  const { data: customerData, loading: customerLoading } = useCustomerQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: discountsData, loading: discountsLoading } = usePersonalDiscountsQuery()
  const navigate = useNavigate()

  const _handleViewAll = (): void => {
    navigate('/my-ftn-shop/my-discounts')
  }

  const _handleCopyCode = (code: string): void => {
    navigator.clipboard.writeText(code)
    addToast({
      message: 'Your personal discount code has been copied to your clipboard.',
      appearance: 'success',
    })
  }

  useEffect(() => {
    if (customerLoading) {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.LOADING,
        },
      }))
    } else if (customerData?.currentCustomer?.customerType === CustomerTypeEnum.GUEST) {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.EMPTY,
        },
      }))
    } else {
      if (discountsLoading) {
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.LOADING,
          },
        }))
      } else if (discountsData?.personalDiscounts?.length) {
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.DISCOUNTS,
          },
        }))
      } else {
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.EMPTY,
          },
        }))
      }
    }
  }, [customerData?.currentCustomer, discountsData?.personalDiscounts])

  const { MOBILE } = DeviceTypeEnum
  const titleAlignment = section.titleAlignment === 'LEFT'
    ? 'left'
    : section.titleAlignment === 'RIGHT'
      ? 'right'
      :'center'

  let discount: PersonalDiscountFragment

  if (state.displayType === DisplayTypeEnum.EMPTY && !showPlaceholder && !section.placeholderBanner) {
    return null
  }

  const isLessThanSixItems = discountsData?.personalDiscounts?.length || 0 <= 6

  return (
    <Container backgroundColor={section?.backgroundColor}>
      <Choose>
        <When condition={state.displayType === DisplayTypeEnum.LOADING}>
          <Loader />
        </When>
        <When condition={state.displayType === DisplayTypeEnum.EMPTY && !!section.placeholderBanner}>
          <PageBanner pageBanner={section.placeholderBanner} />
        </When>
        <When condition={state.displayType === DisplayTypeEnum.EMPTY && showPlaceholder}>
          <Placeholder>
            <Title variant='t3'>My Discounts Placeholder</Title>
            <Paragraph>There is no preview content for this section while the user is logged out or has no personal discounts</Paragraph>
          </Placeholder>
        </When>
        <Otherwise>
          <HeadingContainer>
            <Title
              variant={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 't3' : 't1'}
              className='block-title'
              align={titleAlignment}>
                Discounts for {customerData?.currentCustomer?.firstname}
            </Title>
          </HeadingContainer>
          <For each='discount' of={discountsData?.personalDiscounts || []}>
            <CustomerPromoContainer>
              <Heading variant='h5' >Get {discount?.percentage}% off</Heading>
              <PromoDetails>
                <Details>
                  <Paragraph variant={appData.app.deviceType === MOBILE ? 'p3' : 'p1'}>
                          To activate your discounts, copy this unique promo code and paste it into the promo code box at checkout: </Paragraph>
                  <PromoCodeChip onClick={() => _handleCopyCode(discount?.code)}>
                    <Paragraph
                      variant={appData.app.deviceType === MOBILE ? 'p3' : 'p1'}
                      color={theme.colors.green.viridian}>
                      {discount?.code}
                    </Paragraph>
                  </PromoCodeChip>
                </Details>
                <If condition={!isLessThanSixItems}>
                  <DeviceContainer tablet desktop>
                    <ButtonContainer>
                      <Button className='seeAllBtn' onClick={_handleViewAll} title='Shop All' variant='primary' size='medium'/>
                    </ButtonContainer>
                  </DeviceContainer>
                </If>
              </PromoDetails>
            </CustomerPromoContainer>
            <If condition={discount?.products?.length > 0}>
              <ProductSlider
                showCount={false}
                total={discount?.products?.length}
                products={discount?.discountProducts }
                productsDiscount={discount?.percentage}
                infiniteScroll
                lessThanSixItems={true} />
            </If>
          </For>
          <If condition={!isLessThanSixItems}>
            <DeviceContainer mobile>
              <Button className='seeAllBtn' onClick={_handleViewAll} title='Shop All' variant='primary' size='medium' fullWidth/>
            </DeviceContainer>
          </If>
        </Otherwise>
      </Choose>
    </Container>
  )
}
