import React, { useState } from 'react'

import styled from 'styled-components'

import { LayoutGrid, LayoutRow, LayoutCol } from '@atoms/layout'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { BreadcrumbFragment, SpecialsCategoryFragment, SpecialsChildCategoryFragment, PageSectionGroupFragment } from '@hooks/api'
import { BreadCrumbs, BreadCrumb } from '@molecules/navigation'
import { SpecialCard } from '@molecules/store/SpecialCard'
import { PageSectionGroup } from '@organisms/content'
import { SearchEngineOptimizationParams, SearchEngineOptimization } from '@utility/SearchEngineOptimization'

const Container = styled.div`
  .content-row {
    ${ResponsivePXValue('gap', { mobile: '0' })}
  }
  .main-heading {
    ${ResponsivePXValue('margin', { mobile: '12px', tablet: '32px 0', desktop: '32px 0' })}
  }
`

const SectionContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '32px', desktop: '32px' })}
`

const SpecialsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${ResponsivePXValue('gap', { mobile: '4px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('padding', { mobile: '4px', tablet: '6px', desktop: '6px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '20px', tablet: '200px', desktop: '200px' })}
`

export interface SpecialsProps {
  category: SpecialsCategoryFragment
  loading: boolean
}

interface SpecialsState {

}

const DEFAULT_STATE: SpecialsState = {}

export function Specials({ category }: SpecialsProps): JSX.Element {

  const [state, setState] = useState<SpecialsState>({ ...DEFAULT_STATE })

  let breadCrumb!: BreadcrumbFragment
  let section!: PageSectionGroupFragment
  const hasContent = !!category?.pageContent

  const seo: SearchEngineOptimizationParams = {
    name: 'Faithful to Nature Specials',
    title: 'Faithful to Nature Specials',
    meta: [], // <---- TODO
  }

  let special: SpecialsChildCategoryFragment

  return (
    <Container>
      <SearchEngineOptimization seo={seo} />
      <LayoutGrid>
        <LayoutRow>
          <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
            <BreadCrumbs>
              <BreadCrumb title='Home' href='/' />
              <For each='breadCrumb' of={category?.breadcrumbs || []}>
                <BreadCrumb title={breadCrumb.categoryName} key={breadCrumb.categoryUrlKey} href={`/${breadCrumb.categoryUrlPath}`} />
              </For>
              <BreadCrumb title={category?.name} href={`/${category?.canonicalUrl}`} />
            </BreadCrumbs>
          </LayoutCol>
        </LayoutRow>
        <LayoutRow>
          <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
            <Choose>
              <When condition={hasContent}>
                <SectionContainer>
                  <For each='section' of={category.pageContent.content.sectionGroups}>
                    <PageSectionGroup pageSectionGroup={section} key={section.id} />
                  </For>
                </SectionContainer>
              </When>
              <Otherwise>
                <Heading variant='h2' className='main-heading'>{category.name}</Heading>
              </Otherwise>
            </Choose>
          </LayoutCol>
        </LayoutRow>
        <LayoutRow className='content-row'>
          <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
            <SpecialsContainer>
              <For each='special' of={category.children}>
                <SpecialCard category={special} />
              </For>
            </SpecialsContainer>
          </LayoutCol>
        </LayoutRow>
      </LayoutGrid>
    </Container>
  )

}
