import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { animated, useSpring } from 'react-spring'
import styled, { useTheme } from 'styled-components'
import { v4 } from 'uuid'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { AuthPlugin } from '@api/local/AuthPlugin'
import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { NAVIGATION_DEFAULT_STATE, NavigationPlugin } from '@api/local/NavigationPlugin'
import { Button, LocalIconEnums, Paragraph, Title, SmallLoader } from '@atoms/index'
import { navigation, NavItem, NavItemTypeEnum } from '@client/Navigation'
import { ResponsivePXValue } from '@components/Theme'
import { useCustomerQuery, useGetNavigationQuery, useGetAppQuery, useGetCategoryMenuQueryLazyQuery, useGetFrequentlyBoughtProductsQuery, usePersonalDiscountsQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { CustomerTypeEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'

const SidebarContainer = styled(animated.div)`
  width: 100%;
  position: fixed;
  overflow: scroll;
  scroll-padding-bottom: 0;
  top: 0;
  z-index: 40;
  ${ResponsivePXValue('height', 'CALC(100% + 15px)')}
`

const Container = styled(animated.div)`
  position: relative;
  height: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('width', 'CALC(100% - 50px)')}
  overflow-x: hidden;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '8px')}
  ${ResponsivePXValue('height', '48px')}
  ${(props): string => ResponsivePXValue('border-bottom', `2px solid ${props.theme.colors.grey.desertStorm}`)}
`

const AnimatedContainer = styled(animated.div)`
  ${ResponsivePXValue('height', 'CALC(100% - 110px)')}
  position: relative;
  display: flex;
  flex-direction: column;
`

const ItemsContainer = styled.div`
  flex-grow: 1;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
  overflow-x: hidden;
  &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
  }
`

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props): string => props.theme.colors.white.fantasy};
  ${ResponsivePXValue('height', '48px')}
  .user-name {
    ${ResponsivePXValue('margin-left', '16px')}
  }
  .log-out {
    ${ResponsivePXValue('margin-right', '16px')}
  }
`

const NavigationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('height', '42px')}
  ${ResponsivePXValue('padding-left', '8px')}
  ${(props): string => ResponsivePXValue('border-top', `1px solid ${props.theme.colors.grey.desertStorm}`)}
`

const GiftVoucherItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('gap', '8px')}
  ${ResponsivePXValue('height', '42px')}
  ${ResponsivePXValue('padding-left', '8px')}
  ${(props): string => ResponsivePXValue('border-top', `1px solid ${props.theme.colors.grey.desertStorm}`)}
  background-color: ${(props): string => props.theme.colors.green.swansDown};
  .gift-icon {
    ${ResponsivePXValue('width', '16px')}
    ${ResponsivePXValue('height', '16px')}
  }
`

const LoaderContainer = styled.div`
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
  ${ResponsivePXValue('margin-right', '8px')}
`

const NavigationBackItem = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('height', '42px')}
  ${ResponsivePXValue('padding-left', '8px')}
  ${(props): string => ResponsivePXValue('border-top', `1px solid ${props.theme.colors.grey.desertStorm}`)}

  .back-button {
    position: relative;
    ${ResponsivePXValue('left', '-10px')}
  }
  .back-title {
    position: relative;
    ${ResponsivePXValue('left', '-12px')}
  }
  .back-shop {
    ${ResponsivePXValue('margin-right', '8px')}
  }
`

const NavigationBackText = styled.div`
  flex-grow: 1;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

enum ListPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
}

export interface MobileNavigationMenuProps {
  onSelect: (path: string) => void
}
interface MobileNavigationMenuState {
  mainMenu: Array<NavItem>
  levels: string[]
  incomingLevel: string | null
  outgoingLevel: string | null
  listPosition: ListPosition
  listPreviousPosition: ListPosition
  isTransitioning: boolean
  signOutLoading: boolean
  clickedMenuItem: string | null
  loadingMenuItem: string | null
}

const DEFAULT_STATE: MobileNavigationMenuState = {
  mainMenu: navigation,
  levels: [],
  incomingLevel: null,
  outgoingLevel: null,
  listPosition: ListPosition.CENTER,
  listPreviousPosition: ListPosition.CENTER,
  isTransitioning: false,
  signOutLoading: false,
  clickedMenuItem: null,
  loadingMenuItem: null,
}

export function MobileNavigationMenu({ onSelect }: MobileNavigationMenuProps): JSX.Element {

  const [state, setState] = useState<MobileNavigationMenuState>({ ...DEFAULT_STATE })
  const { data: customerData, refetch: refetchCustomer } = useCustomerQuery()
  const { refetch: refetchMyShop } = useGetFrequentlyBoughtProductsQuery()
  const { refetch: refetchDiscounts } = usePersonalDiscountsQuery()
  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [fetchCategories, { data: menuData }] = useGetCategoryMenuQueryLazyQuery()
  const apolloClient: ApolloClient<NormalizedCacheObject> = useApolloClient() as ApolloClient<NormalizedCacheObject>

  const theme = useTheme()
  const navigate = useNavigate()
  const { MOBILE } = DeviceTypeEnum

  const _handleItemClicked = (nav: NavItem): void => {
    if (nav.sub.length > 0) {
      if (state.isTransitioning) {
        return
      }
      setState((prevState) => update(prevState, {
        incomingLevel: {
          $set: nav.id,
        },
        isTransitioning: {
          $set: true,
        },
      }))
    } else if (nav.path) {
      onSelect?.(nav.path)
    }
  }

  const _handleShopAllClicked = (path: string): void => {
    onSelect?.(path)
  }

  const _handleBackClick = (): void => {
    if (state.isTransitioning) {
      return
    }
    setState((prevState) => update(prevState, {
      outgoingLevel: {
        $set: prevState.levels[prevState.levels.length - 1],
      },
      isTransitioning: {
        $set: true,
      },
    }))
  }

  const _handleToggleMenu = () => {
    NavigationPlugin.shared().toggleNavigation()
  }

  const _handleLoginModal = (e: React.MouseEvent<HTMLDivElement>): void => {
    setState((prevState) => update(prevState, {
      clickedMenuItem: {
        $set: (e.target as HTMLDivElement).innerText,
      },
    }))

    ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    NavigationPlugin.shared().closeNavigation()
  }

  const _handleSignUpModal = (): void => {
    ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SIGN_UP)
    NavigationPlugin.shared().closeNavigation()
  }

  const _handleSignOut = async (): Promise<void> => {
    setState((prevState) => update(prevState, {
      signOutLoading: { $set: true },
    }))

    AuthPlugin.shared().clear()
    await refetchCustomer()
    apolloClient.cache.evict({
      fieldName: 'personalDiscounts',
    })
    apolloClient.cache.evict({
      fieldName: 'frequentlyBoughtProducts',
    })
    refetchDiscounts()
    refetchMyShop()

    NavigationPlugin.shared().closeNavigation()
    setState((prevState) => update(prevState, {
      signOutLoading: { $set: false },
    }))
    navigate('/')
  }

  const _handleBackgroundClose = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault()

    if (e.target === e.currentTarget) {
      NavigationPlugin.shared().toggleNavigation()
    }
  }

  const _handleAnimationRest = () => {
    if (state.incomingLevel) {
      setState((prevState) => update(prevState, {
        listPosition: {
          $set: ListPosition.CENTER,
        },
        listPreviousPosition: {
          $set: ListPosition.RIGHT,
        },
        levels: {
          $apply: (levels) => [...levels, prevState.incomingLevel],
        },
        incomingLevel: {
          $set: null,
        },
      }))
    } else if (state.outgoingLevel) {
      setState((prevState) => update(prevState, {
        listPosition: {
          $set: ListPosition.CENTER,
        },
        listPreviousPosition: {
          $set: ListPosition.LEFT,
        },
        levels: {
          $apply: (levels) => {
            const newLevels = [...levels]
            newLevels.pop()
            return newLevels
          },
        },
        outgoingLevel: {
          $set: null,
        },
      }))
    } else if (state.isTransitioning) {
      setState((prevState) => update(prevState, {
        isTransitioning: {
          $set: false,
        },
        listPosition: {
          $set: ListPosition.CENTER,
        },
        listPreviousPosition: {
          $set: ListPosition.CENTER,
        },
      }))
    }
  }

  useEffect(() => {
    if (menuData?.categories && !state.mainMenu?.[1]?.sub?.length) {
      const newArr = [...state.mainMenu]
      const categories = menuData.categories.items.filter((item) => item.includeInMenu) || []
      for (let i = 0; i < categories.length; i++) {
        const subs: NavItem[] = []
        const subCategories = categories[i].children.filter((item) => item.includeInMenu) || []
        for (let ii = 0; ii < subCategories.length; ii++) {
          const subSubs: NavItem[] = []
          const subSubCategories = subCategories[ii].children.filter((item) => item.includeInMenu) || []
          for (let iii = 0; iii < subSubCategories.length; iii++) {
            subSubs.push({
              id: subSubCategories[iii].uid,
              title: subSubCategories[iii].name,
              path: `/${subSubCategories[iii].canonicalUrl}`,
              type: NavItemTypeEnum.CATEGORY,
              sub: [],
            })
          }
          subs.push({
            id: subCategories[ii].uid,
            title: subCategories[ii].name,
            path: `/${subCategories[ii].canonicalUrl}`,
            type: NavItemTypeEnum.CATEGORY,
            sub: subSubs,
          })
        }
        newArr[1].sub.push({
          id: categories[i].uid,
          title: categories[i].name,
          path: `/${categories[i].canonicalUrl}`,
          type: NavItemTypeEnum.CATEGORY,
          sub: subs,
        })
      }

      setState((prevState) => update(prevState, {
        mainMenu: { $set: newArr },
      }))
    }
  }, [menuData])

  useEffect(() => {
    // TODO: include 'navigationData.navigation.navigationOpen' in function
    NavigationPlugin.shared().preventScroll(navigationData.navigation.navigationOpen)
    if (!navigationData.navigation.navigationOpen) {
      document && enableBodyScroll(document)
      setState((prevState) => update(prevState, {
        levels: {
          $set: [],
        },
        listPosition: {
          $set: ListPosition.CENTER,
        },
        listPreviousPosition: {
          $set: ListPosition.CENTER,
        },
      }))
    } else {
      document && disableBodyScroll(document as unknown as Document, {
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.classList.contains('scroll-lock-ignore')) {
              return true
            }

            el = el.parentElement
          }
        },
      })
    }
    return () => {
      document && enableBodyScroll(document as unknown as Document)
      NavigationPlugin.shared().preventScroll(false)
    }
  }, [navigationData.navigation.navigationOpen])

  useEffect(() => {
    if (state.incomingLevel && state.listPosition === ListPosition.CENTER) {
      setState((prevState) => update(prevState, {
        listPosition: {
          $set: ListPosition.LEFT,
        },
        listPreviousPosition: {
          $set: ListPosition.CENTER,
        },
      }))
    } else if (state.outgoingLevel && state.listPosition === ListPosition.CENTER) {
      setState((prevState) => update(prevState, {
        listPosition: {
          $set: ListPosition.RIGHT,
        },
        listPreviousPosition: {
          $set: ListPosition.CENTER,
        },
      }))
    }
  }, [state.incomingLevel, state.outgoingLevel])

  useEffect(() => {
    if (appData.app.deviceType === MOBILE) {
      if (state.clickedMenuItem === 'Orders') {
        navigate('/me/orders/')
        setState((prevState) => update(prevState, {
          clickedMenuItem: {
            $set: null,
          },
        }))
      }
      if (state.clickedMenuItem === 'Account') {
        navigate('/me/')
        setState((prevState) => update(prevState, {
          clickedMenuItem: {
            $set: null,
          },
        }))
      }
    }
  }, [customerData?.currentCustomer?.customerType])

  useEffect(() => {
    if (appData?.app?.baseCategoryId) {
      fetchCategories({ variables: { rootCategoryId: appData.app.baseCategoryId } })
    }
  }, [appData?.app?.baseCategoryId])

  const sidebarContainerProps = useSpring({
    dspl: navigationData.navigation.navigationOpen ? 1 : 0,
    backgroundColor: navigationData.navigation.navigationOpen ? SiteHelper.getOpaqueColor(theme.colors.black.pureBlack, 0.25) : theme.colors.misc.transparent,
  })
  const containerProps = useSpring({
    left: navigationData.navigation.navigationOpen ? '0%' : '-100%',
  })
  const fromLeft = state.listPreviousPosition === ListPosition.CENTER
    ? '0%'
    : state.listPreviousPosition === ListPosition.LEFT
      ? '-100%'
      : '100%'
  const toLeft = state.listPosition === ListPosition.CENTER
    ? '0%'
    : state.listPosition === ListPosition.LEFT
      ? '-100%'
      : '100%'

  const listProps = useSpring({
    from: { left: fromLeft },
    to: { left: toLeft },
    reset: true,
    config: { mass: 1, tension: 300, friction: 30, clamp: true },
    onRest: _handleAnimationRest,
  })

  let title = ''
  let path = ''
  let currentLevel: NavItem[] = state.mainMenu
  let navItem!: NavItem
  const giftVouchers: NavItem = {
    id: v4(),
    title: 'Gift Vouchers',
    path: '/me/vouchers',
    type: NavItemTypeEnum.NAV,
    sub: [],
  }

  state.levels.forEach((level) => {
    const newLevel = currentLevel.find((item) => {
      return item.id === level
    })
    currentLevel = newLevel.sub
    title = newLevel.title
    path = newLevel.path
  })

  return (
    <SidebarContainer
      className='scroll-lock-ignore'
      style={{
        ...sidebarContainerProps,
        display: sidebarContainerProps.dspl.to((displ) =>
          displ === 0 ? 'none' : 'initial',
        ),
      }} onClick={_handleBackgroundClose}>
      <Container style={containerProps}>
        <Header>
          <Button size='large' variant='logo' icon={LocalIconEnums.LOGO} />
          <Button size='large' variant='nav' icon={LocalIconEnums.CLOSE} onClick={_handleToggleMenu} />
        </Header>
        <AnimatedContainer style={listProps}>
          <If condition={!!title}>
            <NavigationBackItem>
              <NavigationBackText onClick={_handleBackClick}>
                <Button size='large' variant='nav' className='back-button' icon={LocalIconEnums.CHEVRON_LEFT} />
                <Title variant='t3' className='back-title'>{title}</Title>
              </NavigationBackText>
              <If condition={!!path}>
                <Button size='large' variant='text' title='Shop All' className='back-shop' onClick={() => _handleShopAllClicked(path)} />
              </If>
            </NavigationBackItem>
          </If>
          <ItemsContainer>
            <For each='navItem' of={currentLevel}>
              <Choose>
                <When condition={navItem.title === 'Account' || navItem.title === 'Orders'}>
                  <If condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.GUEST}>
                    <NavigationItem onClick={_handleLoginModal} key={navItem.id}>
                      <Paragraph>{navItem.title}</Paragraph>
                      <If condition={!!navItem.sub.length}>
                        <Button size='large' variant='nav' icon={LocalIconEnums.CHEVRON_RIGHT} />
                      </If>
                    </NavigationItem>
                  </If>
                  <If condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED}>
                    <NavigationItem onClick={() => _handleItemClicked(navItem)} key={navItem.id}>
                      <Paragraph>{navItem.title}</Paragraph>
                      <If condition={!!navItem.sub.length}>
                        <Button size='large' variant='nav' icon={LocalIconEnums.CHEVRON_RIGHT} />
                      </If>
                    </NavigationItem>
                  </If>
                </When>
                <Otherwise>
                  <NavigationItem onClick={() => _handleItemClicked(navItem)} key={navItem.id}>
                    <Paragraph>{navItem.title}</Paragraph>
                    <If condition={!!navItem.sub.length || navItem.shouldFetchSubCategories}>
                      <Choose>
                        <When condition={state.loadingMenuItem === navItem.id}>
                          <LoaderContainer>
                            <SmallLoader />
                          </LoaderContainer>
                        </When>
                        <Otherwise>
                          <Button size='large' variant='nav' icon={LocalIconEnums.CHEVRON_RIGHT} />
                        </Otherwise>
                      </Choose>
                    </If>
                  </NavigationItem>
                </Otherwise>
              </Choose>
            </For>
            {/* GIFT VOUCHER LINK REMOVED IN MOBILE FOR M2 APP LAUNCH */}
            {/* <GiftVoucherItem onClick={() => _handleItemClicked(giftVouchers)}>
              <Icon icon={LocalIconEnums.GIFT} color={theme.colors.green.bottleGreen} className='gift-icon' />
              <Paragraph bold color={theme.colors.green.greenVogue}>Gift vouchers</Paragraph>
            </GiftVoucherItem> */}
          </ItemsContainer>
        </AnimatedContainer>
        <BottomContainer>
          <Choose>
            <When condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED}>
              <Paragraph bold className='user-name'>Hi, {customerData.currentCustomer.firstname}</Paragraph>
              <Paragraph bold className='log-out'>
                <Choose>
                  <When condition={state.signOutLoading}>
                    <p>Signing out...</p>
                  </When>
                  <Otherwise>
                    <Button variant='secondary' href='#' onClick={_handleSignOut} title='Sign Out' />
                  </Otherwise>
                </Choose>
              </Paragraph>
            </When>
            <Otherwise>
              <Button variant='nav' size='medium' title='LOGIN' onClick={_handleLoginModal} />
              <Button variant='nav' size='medium' title='SIGNUP' onClick={_handleSignUpModal} />
            </Otherwise>
          </Choose>
        </BottomContainer>
      </Container>
    </SidebarContainer>
  )

}
