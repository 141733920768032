import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { Button } from '@atoms/buttons'
import { LocalIconEnums } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'
import { useCartQuery, useCustomerQuery, useGetAppQuery } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { Modal } from '@molecules/index'
import { Cart } from '@pages/Cart'
import { CustomerTypeEnum, DeviceTypeEnum, MobileOSTypeEnum } from '@uctypes/api/globalTypes'
import { Space } from 'antd'

const Container = styled.div<{isIos: boolean}>`

  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('height', '56px')}
  ${props => () => {
    if (props.isIos) {
      return `
        ${ResponsivePXValue('height', '76px')}
        ${ResponsivePXValue('padding-bottom', '20px')}
      `
    }
  }}
  z-index: 7;
`

const CartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('height', '36px')}
  ${ResponsivePXValue('border-radius', '18px')}
  ${(props): string => ResponsivePXValue('border', `1px solid ${props.theme.colors.grey.silver}`)}
`

const CartCounter = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'open-sans';
  font-weight: 700;

  ${ResponsivePXValue('font-size', '10px')}
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
  ${ResponsivePXValue('border-radius', '12px')}
  ${ResponsivePXValue('margin-right', '8px')}

  background-color: ${(props): string => props.theme.colors.green.deYork};
  color: ${(props): string => props.theme.colors.white.pureWhite};
`

const Spacer = styled.div`
  flex-grow: 0.20;
`

interface BottomNavState {
  open: boolean
  scrollPositions: string[]
}

const DEFAULT_STATE: BottomNavState = {
  open: false,
  scrollPositions: [],
}

export function BottomNavigationBar(): JSX.Element {
  const { cartId } = useCartId()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: customerData } = useCustomerQuery()
  const { data: cartData, loading: cartLoading } = useCartQuery({ variables: { cartId }, skip: !cartId })

  const navigate = useNavigate()
  const location = useLocation()
  const [state, setState] = useState<BottomNavState>({ ...DEFAULT_STATE })
  const isNativeApp = appData.app.isNativeApp
  const { MOBILE } = DeviceTypeEnum  

  const _handleCustomerButtonClicked = () => {
    navigate('/me/')
  }

  const _handleLoginModal = (): void => {
    ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
  }

  const _handleToggleSearch = (): void => {
    NavigationPlugin.shared().toggleSearch()
  }

  const _handleBack = (): void => {
    if (location.pathname === '/') {
      document?.body?.scrollTo(0, 0)
    } else {
      setState((prevState) => update(prevState, {
        [location.pathname]: { $set: window.scrollY },
      }))
      navigate(-1)
    }
  }

  useEffect(() => {
    const savedPositions = state.scrollPositions[location.pathname]
    if (savedPositions) {
      setTimeout(() => {
        document?.body?.scrollTo(0, savedPositions)
      }, 100)
    }
  }, [location.pathname])

  const _handleCart = (): void => {
    if (appData.app.isNativeApp || (appData.app.deviceType === DeviceTypeEnum.MOBILE)) {
      setState((prevState) => update(prevState, {
        open: { $set: true },
      }))
    } else {
      navigate('/cart/')
    }

  }

  const _closeCart = (): void => {
    setState((prevState) => update(prevState, {
      open: { $set: false },
    }))
  }

  return (
    <>
      <Modal open={state.open} onClose={_closeCart} title='Cart' allowCloseOnBackgroundClick={true}>
        <Cart isModal onClose={_closeCart} />
      </Modal>
      <Container className='gonative-bottom-navigation-bar' isIos={appData.app.mobileOSType === MobileOSTypeEnum.APPLE}>
        <If condition={appData.app.isNativeApp}>
          <Button icon={LocalIconEnums.CHEVRON_LEFT} onClick={_handleBack} variant='nav' />
        </If>
        <Button icon={LocalIconEnums.HOME} href='/' variant='nav' />
        <If condition={!appData.app.isNativeApp}>
          <Button icon={LocalIconEnums.SEARCH_OUTLINE} onClick={_handleToggleSearch} variant='nav' />
        </If>
        <CartContainer onClick={_handleCart}>
          <Button icon={LocalIconEnums.CART_FILLED} variant='nav' loading={cartLoading} />
          <CartCounter>
            {cartData?.cart?.totalQuantity || 0}
          </CartCounter>
        </CartContainer>
        <If condition={!isNativeApp && !(appData.app.deviceType === MOBILE)}>
          <Choose>
            <When condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.GUEST}>
              <Button icon={LocalIconEnums.HEART_OUTLINE} onClick={_handleLoginModal} variant='nav' />
            </When>
            <Otherwise>
              <Button icon={LocalIconEnums.HEART_OUTLINE} href='/my-ftn-shop' variant='nav' />
            </Otherwise>
          </Choose>
        </If>
        <Choose>
          <When condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.GUEST}>
            <Button icon={LocalIconEnums.ACCOUNT_NEW} onClick={_handleLoginModal} variant='nav' />
          </When>
          <Otherwise>
            <Button icon={LocalIconEnums.ACCOUNT_NEW} onClick={_handleCustomerButtonClicked} variant='nav' />
          </Otherwise>
        </Choose>
        <If condition={isNativeApp || appData.app.deviceType === MOBILE}>
          <Spacer />
        </If>
      </Container>
    </>

  )

}
