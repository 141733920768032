import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { CheckoutPlugin } from '@api/local/CheckoutPlugin'
import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Divider } from '@atoms/layout'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, OrderItemFragment, useReorderItemsMutation, useGetStoreConfigQuery, CustomerOrderFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { useSimpleToasts } from '@simple/toasts'
import { CarrierTypeEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { OrderProductSlider } from '../shop/OrderProductSlider'

const OrdersContainer = styled.div`
  ${ResponsivePXValue('padding', { mobile: '10px 16px', tablet: '24px', desktop: '24px' })}
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  ${(props): string => ResponsivePXValue('border-bottom', `8px solid ${props.theme.colors.green.magicMint}`)}
  ${(props): string => ResponsivePXValue('border-top', `8px solid ${props.theme.colors.green.magicMint}`)}
`

const OrderStatusContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('gap', '8px')}

`

const ExtraContainer = styled.div`
  overflow-x: scroll;
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '40px', tablet: '55px', desktop: '55px' })}
`

const Status = styled.div<{ bgColor: string }>`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  justify-content: center;
  align-items: center;
  ${ResponsivePXValue('gap', { mobile: '3.8px', tablet: '6.5px', desktop: '6.5px' })}
  ${ResponsivePXValue('padding', { mobile: '0px 5px', tablet: '', desktop: '' })}
`

const IconContainer = styled.div`
  ${ResponsivePXValue('height', { mobile: '12px', tablet: '16px', desktop: '16px' })}
  ${ResponsivePXValue('width', { mobile: '12px', tablet: '16px', desktop: '16px' })}
  flex-shrink: 0;
`

const OrderInfoContainer = styled.div<{ isActive: boolean, pendingPayment: boolean }>`
  ${ResponsivePXValue('margin-top', { mobile: '0px', tablet: '10px', desktop: '10px' })}
  ${({ pendingPayment }) => pendingPayment && ResponsivePXValue('margin-top', '0px')}

  .order-info-divider {
    ${ResponsivePXValue('margin', { mobile: '12px 0', tablet: '16px 0', desktop: '16px 0' })}
  }
`

const DelInfo = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', { mobile: '', tablet: '', desktop: '' })}
  position: relative;

  .customerDetails {
    display: flex;
    align-items: center;
    ${ResponsivePXValue('gap', '11px')}
  }

  .locationIcon {
    width: auto;
    height: auto;
  }

  .userIcon {
    width: auto;
    height: auto;
  }
`

const DelInfoWrapper = styled.div``

const MoreInfo = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;

    ${ResponsivePXValue('margin', { mobile: '18px 0px 12px', tablet: '38px 0px 16px', desktop: '38px 0px 16px' })}
  .infoText {
    margin: 0;
  }
`

const MoreInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', { mobile: '0px', tablet: '4px', desktop: '4px' })}
  flex-grow: 0;
`

const StatusChip = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${ResponsivePXValue('padding', { mobile: '4px 12px', tablet: '8px 12px', desktop: '8px 12px' })}
  ${ResponsivePXValue('border-radius', '100px')}
  background-color: ${({ isActive, theme }) => isActive ? theme.colors.green.magicMint : theme.colors.grey.athens};

  .chipText {
    margin: 0
  }

`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  ${ResponsivePXValue('justify-content', { mobile: 'space-between', tablet: 'flex-start', desktop: 'flex-start' })}
  ${ResponsivePXValue('gap', '16px')}
  ${ResponsivePXValue('padding-top', { mobile: '12px', tablet: '18px', desktop: '18px' })}
`

const EftPaymentMsg = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', '12px')}
  background-color: ${({ theme }): string => SiteHelper.getOpaqueColor(theme.colors.orange.casablanca, 0.3)};
  ${ResponsivePXValue('padding', { mobile: '12px 16px', tablet: '16px', desktop: '16px' })}
  width: 100%;
  ${ResponsivePXValue('margin-bottom', { tablet: '16px', desktop: '16px' })}
`

const BankTable = styled.div`
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '50%', desktop: '50%' })}
  ${ResponsivePXValue('padding', { mobile: '12px 0', tablet: '12px 0', desktop: '12px 0' })}
  ${ResponsivePXValue('gap', '12px')}
`

const BankRow = styled.div`
  display: flex;

  > * {
    flex-basis: 50%;
    display: flex;
    align-items: flex-start;
  }
`

const PaymentMsg = styled.div`
  background-color: ${({ theme }): string => SiteHelper.getOpaqueColor(theme.colors.orange.casablanca, 0.3)};
  ${ResponsivePXValue('padding', { mobile: '12px 16px 16px', tablet: '24px 16px', desktop: '24px 16px' })}
  ${ResponsivePXValue('margin-bottom', { tablet: '16px', desktop: '16px' })}

`

export interface OrderProps {
  order: CustomerOrderFragment
  isActive: boolean

}

interface StatusOption {
  label: string
  textColor: string
  bgColor: string
}

interface OrderState {
  paymentMethod: string
  orderStage: string
  reOrdering: boolean
}

const DEFAULT_STATE: OrderState = {
  paymentMethod: '',
  orderStage: '',
  reOrdering: false,
}

export function Order({ order, isActive }: OrderProps): JSX.Element {

  const { colors } = useTheme()
  const navigate = useNavigate()
  const { addToast } = useSimpleToasts()
  const { MOBILE } = DeviceTypeEnum
  const [{ orderStage, reOrdering }, setState] = useState<OrderState>({ ...DEFAULT_STATE })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: storeData } = useGetStoreConfigQuery()
  const { status, shippingAddress, number, orderDate, paymentMethods, shippingMethod, items: products, invoices, total: { baseGrandTotal } = {} } = order
  const [reOrder] = useReorderItemsMutation()

  useEffect(() => {
    setState(prevState => update(prevState, {
      orderStage: { $set: status },
      paymentMethod: { $set: paymentMethods[0]?.name },
    }))
  }, [])

  const isSplit = order.shipmentSources?.length > 1
  const carrierType = order.carrierType || CarrierTypeEnum.UNASSIGNED
  const pendingPayment = invoices.length === 0
  const eftPayment = paymentMethods[0]?.type === 'banktransfer'
  const nonCardPayment = paymentMethods[0]?.type === 'checkmo' || paymentMethods[0]?.type === 'ozow'

  const _handleReorder = async (): Promise<void> => {
    CheckoutPlugin.shared().reset()
    setState(prevState => update(prevState, {
      reOrdering: { $set: true },
    }))
    try {
      const result = await reOrder({
        variables: {
          orderNumber: order.number,
        },
      })
      if (result.data.reorderItems.userInputErrors?.length) {
        for (let u = 0; u < result.data.reorderItems.userInputErrors.length; u++) {
          addToast({
            message: result.data.reorderItems.userInputErrors[u].message,
            appearance: 'error',
          })
        }
      } else {
        addToast({
          message: `Successfully added ${order.items.length} products to your cart.`,
          appearance: 'success',
        })
      }
      navigate('/cart')
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    } finally {
      setState(prevState => update(prevState, {
        reOrdering: { $set: false },
      }))
    }

  }

  const statusThreeLabel = carrierType === CarrierTypeEnum.COLLECT
    ? 'Enroute to store'
    : carrierType === CarrierTypeEnum.DELIVERY
      ? 'Out for delivery'
      : 'Digital delivery'

  const statusFourLabel = carrierType === CarrierTypeEnum.COLLECT
    ? 'Ready For Collection'
    : carrierType === CarrierTypeEnum.DELIVERY
      ? 'Delivered'
      : 'Digital delivery'
  const statusOptions: StatusOption[] = [
    {
      label: 'Confirmed',
      textColor: orderStage === 'Confirmed' ? colors.green.greenVogue : colors.grey.silver,
      bgColor: orderStage === 'Confirmed' ? colors.green.magicMint : colors.grey.silver,
    },
    {
      label: 'Being packed',
      textColor: orderStage === 'Being packed' || orderStage === 'Processing' ? colors.green.greenVogue : colors.grey.silver,
      bgColor: orderStage === 'Being packed' || orderStage === 'Processing'? colors.green.magicMint : colors.grey.silver,
    },
    {
      label: statusThreeLabel,
      textColor: orderStage === 'On Delivery' ? colors.green.greenVogue : colors.grey.silver,
      bgColor: orderStage === 'Enroute to store' || orderStage === 'On Delivery' ? colors.green.magicMint : colors.grey.silver,
    },
    {
      label: statusFourLabel,
      textColor: orderStage === 'Ready For Collection' ? colors.green.greenVogue : colors.grey.silver,
      bgColor: orderStage === 'Ready For Collection' ? colors.green.magicMint : colors.grey.silver,
    },
  ]

  let statusOption: StatusOption
  let index: number

  return (
    <OrdersContainer>
      {/* <If condition={!!isActive && !pendingPayment}>
        <ExtraContainer>
          <OrderStatusContainer>
            <For each='statusOption' index='index' of={statusOptions}>
              <Status key={index} bgColor={statusOption.bgColor}>
                <Paragraph
                  variant={appData.app.deviceType === MOBILE ? 'p3' : 'p1'}
                  color={statusOption.textColor}
                  align='center'
                  className='statusTitle'>
                  {statusOption.label}
                </Paragraph>
              </Status>
            </For>
          </OrderStatusContainer>
        </ExtraContainer>
      </If> */}
      <OrderInfoContainer isActive={isActive} pendingPayment={pendingPayment}>
        {/* <DelInfo>
          <DelInfoWrapper>
            <Paragraph
              variant='p1'
              color={colors.green.bottleGreen}
              className='customerDetails'
              bold={true}>
              <IconContainer>
                <Icon icon={LocalIconEnums.LOCATION} color={colors.green.bottleGreen} className='locationIcon' />
              </IconContainer>
              {
                shippingMethod === null
                  ? 'Digital delivery'
                  :shippingMethod.includes('Pargo')
                    ? 'To be collected from Pargo Collection Point'
                    : shippingMethod.includes('Courier') || shippingMethod.includes('Express') || shippingMethod.includes('National Deliveries')
                      ? `${shippingAddress.street}, ${shippingAddress.suburb}, ${shippingAddress.city}`
                      : 'To be collected from V&A Retail Store'
              }
            </Paragraph>
          </DelInfoWrapper>
          <If condition={!!shippingAddress}>
            <DelInfoWrapper>
              <Paragraph variant='p1' color={colors.green.bottleGreen} className='customerDetails'>
                <IconContainer>
                  <Icon icon={LocalIconEnums.USER} color={colors.green.bottleGreen} className='userIcon' />
                </IconContainer>
                {shippingAddress?.firstname} {shippingAddress?.lastname} - {shippingAddress?.telephone}
              </Paragraph>
            </DelInfoWrapper>
          </If>
        </DelInfo> */}
        {/* <Divider className='order-info-divider' /> */}
        <MoreInfo isActive={isActive}>
          <MoreInfoWrapper>
            <Paragraph
              variant='p1'
              color={colors.green.bottleGreen}
              bold={true}
              className='infoText'>
              Order no: {number}
            </Paragraph>
            <Paragraph
              variant={appData.app.deviceType === MOBILE ? 'p3' : 'p1'}
              color={colors.green.bottleGreen}
              className='infoText'>
              Order date: {orderDate}
            </Paragraph>
          </MoreInfoWrapper>
          {/* <StatusChip isActive={isActive}>
            <Paragraph
              variant={appData.app.deviceType === MOBILE ? 'p3' : 'p2'}
              color={isActive ? colors.green.greenVogue : colors.green.bottleGreen}
              className='chipText'>
              {
                status === 'Confirmed'
                  ? 'Awaiting payment'
                  : status === 'On Delivery' && carrierType === CarrierTypeEnum.COLLECT
                    ? 'Enroute to store'
                    : status
              }
            </Paragraph>
          </StatusChip> */}
          <StatusChip isActive={isActive}>
            <Paragraph
              variant={appData.app.deviceType === MOBILE ? 'p3' : 'p2'}
              color={isActive ? colors.green.greenVogue : colors.green.bottleGreen}
              className='chipText'>
              {isActive ? 'Active Order' : 'Past Order'}
            </Paragraph>
          </StatusChip>
        </MoreInfo>
        <If condition={orderStage === 'Pending' && isActive}>
          <If condition={!!eftPayment && !!order.total.totalDue.value}>
            <EftPaymentMsg>
              <Paragraph variant='p1'>
                Make payment of <strong>R{order.total.totalDue.value}</strong> to confirm your order:
              </Paragraph>
              <BankTable>
                <BankRow>
                  <Paragraph>
                    Bank name:
                  </Paragraph>
                  <Paragraph bold>
                    Nedbank
                  </Paragraph>
                </BankRow>
                <BankRow>
                  <Paragraph>
                    Account number:
                  </Paragraph>
                  <Paragraph bold>
                    1010135546
                  </Paragraph>
                </BankRow>
                <BankRow>
                  <Paragraph>
                    Account name:
                  </Paragraph>
                  <Paragraph bold>
                    Faithful To Nature
                  </Paragraph>
                </BankRow>
                <BankRow>
                  <Paragraph>
                    Account Type:
                  </Paragraph>
                  <Paragraph bold>
                    Current Account
                  </Paragraph>
                </BankRow>
                <BankRow>
                  <Paragraph>
                    Branch Code:
                  </Paragraph>
                  <Paragraph bold>
                    198765 (for Standard bank users 19876500)
                  </Paragraph>
                </BankRow>
                <BankRow>
                  <Paragraph>
                    Branch Name:
                  </Paragraph>
                  <Paragraph bold>
                    Gardens
                  </Paragraph>
                </BankRow>
                <BankRow>
                  <Paragraph>
                    Reference:
                  </Paragraph>
                  <Paragraph bold>
                    {number}
                  </Paragraph>
                </BankRow>
                <BankRow>
                  <Paragraph>
                    Amount:
                  </Paragraph>
                  <Paragraph bold>
                    {SiteHelper.formatCurrency(baseGrandTotal)}
                  </Paragraph>
                </BankRow>
              </BankTable>
              <Paragraph>
                Please email POP to <strong>{storeData?.storeConfig?.transEmailIdentSupportEmail}</strong> to complete payment
              </Paragraph>
            </EftPaymentMsg>
          </If>
          <If condition={!!nonCardPayment && orderStage === 'Pending' }>
            <PaymentMsg>
              <Paragraph variant='p1'>
                Payment of <strong>R{order.total.totalDue.value}</strong> needed to confirm your order. Please contact customer service on <strong>{storeData?.storeConfig?.transEmailIdentSupportEmail}</strong>  or <strong>{storeData?.storeConfig?.generalStoreInformationPhone}</strong>
              </Paragraph>
            </PaymentMsg>
          </If>
        </If>
        <DeviceContainer desktop tablet>
          <OrderProductSlider products={products as OrderItemFragment[]} total={products.length} />
          <ButtonsContainer>
            <Button
              variant='ghost'
              title='REORDER'
              fullWidth={appData.app.deviceType === MOBILE}
              onClick={_handleReorder}
              loading={reOrdering}/>
            <Button
              variant='ghost'
              title='DETAILS'
              fullWidth={appData.app.deviceType === MOBILE}
              href={`${number}`}/>
          </ButtonsContainer>
        </DeviceContainer>
        <DeviceContainer mobile>
          <OrderProductSlider products={products as OrderItemFragment[]} total={products.length} />
          <ButtonsContainer>
            <Button
              variant='ghost'
              title='REORDER'
              fullWidth={appData.app.deviceType === MOBILE}
              onClick={_handleReorder}
              loading={reOrdering}/>
            <Button
              variant='ghost'
              title='DETAILS'
              fullWidth={appData.app.deviceType === MOBILE}
              href={`${number}`}/>
          </ButtonsContainer>
        </DeviceContainer>
      </OrderInfoContainer>
    </OrdersContainer>
  )

}
