import React from 'react'

import styled from 'styled-components'

import { Tag } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'

interface FillProps {
  color?: string
  percentage: number
  count: number
  total: number
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('height', '28px')}

`
const Bar = styled.div`
  ${ResponsivePXValue('width', '243px')}
  ${ResponsivePXValue('height', '6px')}
  ${ResponsivePXValue('border-radius', '10px')}
  ${ResponsivePXValue('margin', '0 10px 0 0')}
  background-color: ${theme.colors.grey.gallery};
`

const Fill = styled.div<FillProps>`
  ${ResponsivePXValue('height', '6px')}
  ${ResponsivePXValue('border-radius', '10px')}
  ${(props) => ResponsivePXValue('width', `${(props.count/props.total * 100)}%`)}
  background-color: ${(props): string => props.color || theme.colors.orange.casablanca};
`

export interface PercentageBarProps {
  color?: string
  className?: string
  percentage: number
  count?: number
  totalReviews: number
}

export function PercentageBar({ color, percentage, className, count, totalReviews }: PercentageBarProps): JSX.Element {

  return (
    <Container className={className} >
      <Bar>
        <Fill color={color} percentage={percentage} count={count} total={totalReviews}/>
      </Bar>
      <Tag variant='t4'>{percentage}</Tag>
    </Container>
  )

}
