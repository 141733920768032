import { createGlobalStyle } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export const GlobalStyle = createGlobalStyle`
  
  ol {
    list-style-type: decimal;
    ${ResponsivePXValue('margin-bottom', '12px')}
  }

  ul {
    list-style-type: disc;
    ${ResponsivePXValue('margin-bottom', '12px')}
  }

  li {
    display: list-item;
    font-family: open-sans;
    font-weight: 400;
    color: ${(props): string => props.theme.colors.green.bottleGreen};
    ${ResponsivePXValue('line-height', { mobile: '18px', tablet: '20px', desktop: '22px' })}
    ${ResponsivePXValue('font-size', { mobile: '11px', tablet: '14px', desktop: '14px' })}
    ${ResponsivePXValue('margin-left', { mobile: '20px', tablet: '20px', desktop: '20px' })}
  }

  h1 {
    font-family: proxima-nova;
    font-weight: 600;
    ${ResponsivePXValue('font-size', { mobile: '20px', tablet: '38px', desktop: '38px' })}
    ${ResponsivePXValue('line-height', { mobile: '28px', tablet: '44px', desktop: '44px' })}
  }

  h2 {
    font-family: open-sans;
    font-weight: 700;
    ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '28px', desktop: '28px' })}
    ${ResponsivePXValue('line-height', { mobile: '22px', tablet: '36px', desktop: '36px' })}
  }

  h3 {
    font-family: proxima-nova;
    font-weight: 600;
    ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '24px', desktop: '24px' })}
    ${ResponsivePXValue('line-height', { mobile: '24px', tablet: '30px', desktop: '30px' })}
    ${ResponsivePXValue('margin', { mobile: '16px 0 12px', tablet: '24px 0 12px', desktop: '24px 0 12px' })}
  }

  h4 {
    font-family: proxima-nova;
    font-weight: 600;
    ${ResponsivePXValue('font-size', { mobile: '14px', tablet: '20px', desktop: '20px' })}
    ${ResponsivePXValue('line-height', { mobile: '20px', tablet: '26px', desktop: '26px' })}
  }

  h5 {
    font-family: open-sans;
    font-weight: 700;
    ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '18px', desktop: '18px' })}
    ${ResponsivePXValue('line-height', { mobile: '18px', tablet: '24px', desktop: '24px' })}
  }

  a {
    cursor: pointer;
    white-space: nowrap;
    font-weight: 400;
    color: ${(props): string => props.theme.colors.blue.curiousBlue};

    &:hover {
      font-weight: 700;
      color: ${(props): string => props.theme.colors.black.pureBlack};
    }
  }

  p {
    font-family: open-sans;
    color: ${(props): string => props.theme.colors.green.bottleGreen};
    font-weight: 400;
    ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}
    ${ResponsivePXValue('line-height', { mobile: '18px', tablet: '24px', desktop: '24px' })}
    ${ResponsivePXValue('margin-bottom', '12px')}
  }

  strong {
    font-weight: 600;
  }

  table {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.grey.stormDust};
    border-collapse: collapse;
  }

  tr {
    border: 1px solid ${({ theme }) => theme.colors.grey.stormDust};
  }

  th, td {
    ${ResponsivePXValue('padding', { mobile: '5px', tablet: '5px', desktop: '5px' })}
    font-family: open-sans;
    color: ${(props): string => props.theme.colors.green.bottleGreen};
    font-weight: 400;
    ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}
    ${ResponsivePXValue('line-height', { mobile: '18px', tablet: '20px', desktop: '22px' })}
  }

  th {
    border: 1px solid ${({ theme }) => theme.colors.grey.stormDust};
    background: ${({ theme }) => theme.colors.grey.gallery};
  }

  td {
    border: 1px solid ${({ theme }) => theme.colors.grey.stormDust};
  }

  td:first-child, th:first-child {
    border-left: none;
  }

`
